var _a;
import { create } from "zustand";
import moment from "moment";
import { isMeldScheduled, isMeldSchedulable } from "@pm-frontend/shared/utils/meld-utils";
import { getAssignedMaint } from "@pm-frontend/shared/utils/assignment-utils";
export const getCalendarDroppableId = (personaType, persona, date) => {
  return `${personaType}-${persona.id}-${date}`;
};
export const getScheduleTargetDataFromDroppableId = (droppableId) => {
  const splitId = droppableId.split("-");
  const personaType = splitId[0] === "vendor" ? "vendor" : "agent";
  const personaId = splitId[1];
  const timeString = splitId[2];
  const startTime = moment(parseInt(timeString, 10));
  return { personaType, personaId, startTime };
};
export const getCalendarDraggableId = (meldId) => {
  return meldId.toString();
};
export const getCalendarMeldFromDraggableId = (draggableId) => draggableId;
export const getCalendarDraggableType = (meld, scheduleTargetData) => {
  const assignedMaintenance = getAssignedMaint(meld);
  if (!assignedMaintenance) {
    if (scheduleTargetData.personaType === "agent") {
      return "assignAndScheduleMeld";
    } else if (scheduleTargetData.personaType === "vendor") {
      return "assignMeld";
    }
  }
  if (assignedMaintenance) {
    const meldIsScheduled = isMeldScheduled(meld);
    const meldIsSchedulable = isMeldSchedulable(meld);
    const targetMatchesCurrentAssignee = assignedMaintenance.type === "ManagementAgent" && scheduleTargetData.personaType === "agent" && assignedMaintenance.in_house_servicers.some((agent) => agent.id.toString() === scheduleTargetData.personaId) || assignedMaintenance.type === "Vendor" && scheduleTargetData.personaType === "vendor" && assignedMaintenance.vendor.id.toString() === scheduleTargetData.personaId;
    if (!meldIsScheduled && meldIsSchedulable && targetMatchesCurrentAssignee) {
      return "scheduleMeld";
    } else if (!meldIsScheduled && meldIsSchedulable && !targetMatchesCurrentAssignee) {
      if (scheduleTargetData.personaType === "agent") {
        return "reassignAndScheduleMeld";
      } else if (scheduleTargetData.personaType === "vendor") {
        return "reassignMeld";
      }
    } else if (meldIsScheduled && meldIsSchedulable && targetMatchesCurrentAssignee) {
      return "rescheduleMeld";
    }
  }
  return "noop";
};
const doesSegmentMatchAppt = (segment, appointment) => {
  var _a2;
  if (segment.event.dtstart && segment.event.dtend && ((_a2 = appointment.availability_segment) == null ? void 0 : _a2.event.dtstart) && appointment.availability_segment.event.dtend) {
    return segment.event.dtstart === appointment.availability_segment.event.dtstart && segment.event.dtend === appointment.availability_segment.event.dtend;
  }
  return false;
};
export const isSegmentScheduled = (segment, meld) => {
  if (meld.managementappointment.length > 0) {
    return meld.managementappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  } else if (meld.vendorappointment.length > 0) {
    return meld.vendorappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  }
  return false;
};
const CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-calendar-redesign-enabled-${((_a = window.PM.user) == null ? void 0 : _a.id) || "karma-test-fix"}`;
const readCalendarStateFromLocalStorage = () => {
  try {
    const rawKey = window.localStorage.getItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
    if (rawKey !== null) {
      return !!JSON.parse(rawKey);
    }
  } catch (e) {
    return false;
  }
  return false;
};
const writeCalendarStateToLocalStorage = (value) => {
  try {
    window.localStorage.setItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
    return;
  } catch (e) {
    return;
  }
};
export const shouldShowCalendarRedesignDueToMobile = window.matchMedia(`(min-width: 800px)`);
export const useCalendarRedesignToggleStore = create((set) => ({
  enabled: shouldShowCalendarRedesignDueToMobile.matches && readCalendarStateFromLocalStorage(),
  actions: {
    toggleCalendarRedesignEnabled: () => {
      set((state) => {
        writeCalendarStateToLocalStorage(!state.enabled);
        location.reload();
        return { enabled: !state.enabled };
      });
    }
  }
}));
export const isEventAvailability = (event) => {
  var _a2, _b;
  return !(typeof ((_a2 = event.managementavailabilitysegment) == null ? void 0 : _a2.scheduled_management_appointment) === "number" || typeof ((_b = event.vendoravailabilitysegment) == null ? void 0 : _b.scheduled_vendor_appointment) === "number");
};
